.btn {
    background-color: $color-1;
    border: none;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 135px;
    height: 60px;

    font-weight: bold;
    font-size: $font-size-16;
    color: #050402;
    transition: all 0.2s ease-in;
   
    &:focus {
        outline: none;
    }
    &:focus-visible {
        outline: none;
    }
    &:active:focus {
        outline: none;
    }
    &:hover {
        width: 142px;
        transition: all 0.2s ease-in;
    }
}
