// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

h2.visibilityCheck, h3.visibilityCheck, h4.visibilityCheck, h5.visibilityCheck, p.visibilityCheck {
    transform: translateY(100px);
    transition: 1s;
    opacity: 0;
    &.isVisible {
        transform: translateY(0);
        transition: 1s;
        opacity: 1;
    }
}

img.visibilityCheck {
    transition: 1s;
    opacity: 0;
    &.isVisible {
        transition: 1s;
        opacity: 1;
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';

a.btn-link {
    background: $color-1;
    width: 180px;
    height: 60px;
    transition: 0.3s;
    h5 {
        font-weight: bold;
        font-size: 16px;
        color: #050402;
    }
    .icon {
        opacity: 0;
        transition: 0.3s;
    }
    &:hover {
        background: $color-1;
        width: 190px;
        transition: 0.3s;
        .icon {
            opacity: 1;
            transition: 0.3s;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        .text.pr2 {
            padding: 0;
        }
        .icon {
            display: none;
        }
    }
}






/*******************************************************************************
* ACCUEIL
*******************************************************************************/

.section_video {
    &__wrapper {
        position: relative;
        height: 765px;
        &.rempli {
            margin-bottom: -3px;
            height: 665px;
            video {
                height: 665px;
            }
            @media screen and (max-width: 1225px){
                height: initial;
                video {
                    height: initial;
                }
            }
        }
        video {
            width: 100%;
            height: 765px;
            position: absolute;
            object-fit: cover;
        }
        &_overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 3px;
            background:rgba(0, 0, 0, .35);//hsla(9, 100%, 64%, 0.4);//
        }
        &_caption {
            position: absolute;
            top: 40%;
            left: 5%;
            right: 5%;
            h4, h2, p {
                color: $color-2;
            }
            h3 {
                font-weight: bold;
                font-size: $font-size-20;
            }
            h2 {
                font-weight: bold;
                font-size: $font-size-40;
                padding: 15px 0 12px 0;
            }
            div.main {
                flex: 0 0 auto;
                margin-right: auto;
            }   
        }

        &_caption2 {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 35%;
            height: 175px;
            margin-right: 5%;
            padding: 0 5%;
            background: $color-1;
            .icon {
                padding-right: 60px;
            }
            .text {
                border-left: 2px solid #B1B1B1;
                padding-left: 15px;
                h5 {
                    font-weight: bold;
                    font-size: $font-size-18;
                    color: #050402;
                    padding-bottom: 7px;
                }
                div.phonenumber {
                    font-weight: bold;
                    font-size: $font-size-36;
                    color: #050402;
                }
            }
        }
        &_caption3 {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 30%;
            height: 212px;
            padding: 35px 50px;
            background: #FFFFFFE8;
            border-top-left-radius: 20px;
            .section-text {
                h4 {
                    font-weight: bold;
                    font-size: $font-size-20;
                    color: $color-black;
                    line-height: 1.3;
                    padding: 0 0 35px 0;
                }
            }
        }
    }
    @media screen and (max-width: 1550px){
        &__wrapper {
            &_caption {
                //top: 45%;
            }
        }
        @media screen and (max-width: 1415px){
            &__wrapper {
                &_caption {
                    div.main {
                        flex: initial;
                    }
                }
                &_caption2 {
                    .text div.phonenumber {
                        font-size: 1.5rem;
                    }
                }
                &_caption3 {
                    bottom: 1px;
                }
            }
            @media screen and (max-width: 1225px){
                &__wrapper {
                    height: initial;
                    video {
                        width: 100%;
                        height: auto;
                        position: initial;
                        object-fit: initial;
                    }
                    &_caption {
                        top: 35%;
                    }
                    &_caption2 {
                        padding: 0 5%;
                        position: initial;
                        width: 100%;
                        margin-right: 0;
                        margin-top: -3px;
                        .text div.phonenumber {
                            font-size: 1.8rem;
                        }
                    }
                    &_caption3 {
                        position: initial;
                        height: initial;
                        width: 100%;
                        padding: 0;
                        background: lightgrey;
                        border-top-left-radius: 0;
                        .section-text {
                            padding: 50px 5%;
                        }
                    }
                    &_overlay {
                        bottom: 417px;
                        &.rempli {
                            bottom: 0;
                        }
                    }
                }
                @media screen and (max-width: 1024px){
                    &__wrapper {
                        &_overlay {
                            bottom: 416px;
                        }
                    }
                    @media screen and (max-width: $size-sm-max){
                        &__wrapper {
                            &_caption {
                                top: 28%;
                            }
                            &_overlay {
                                bottom: 411px;
                            }
                        }
                        @media screen and (max-width: $size-xs-max){
                            &__wrapper {
                                &_caption {
                                    top: 40%;
                                    h2 {
                                        font-size: 1.8rem;
                                    }
                                }
                                &_overlay {
                                    bottom: 405px;
                                }
                            }
                            @media screen and (max-width: 375px){
                                &__wrapper {
                                    &_caption {
                                        top: 33%;
                                        h2 {
                                            font-size: 1.7rem;
                                        }
                                    }
                                }
                                @media screen and (max-width: 360px){
                                    &__wrapper {
                                        &_caption {
                                            top: 35%;
                                            h2 {
                                                font-size: 1.55rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }    
            }
        }
    }
}

#section-carousel-text {
    padding: 100px 5% 100px 0;
    .main-content {
        position: relative;
        width: 55%;
        .item {
            .col-xs-12.txt {
                padding-left: 0;
                padding-right: 0; 
                    
            }
        }   
        .owl-nav {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            width: 100%;
            padding: 0 5%;
            display: flex;
            align-items: center;
            justify-content: space-between;  
        }
    }
    .text-side {
        width: 45%;
        background: #F9F7F0;
        border: 3px solid $color-1;
        margin-left: 20px;
        padding: 0 50px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-black;
            padding-bottom: 50px; 
        }
        a.btn-link {
            width: 230px;
            margin-top: 35px;
            &:hover {
                width: 240px;
            }
        }
    }
    @media screen and (max-width: 1450px) {
        .text-side h3 {
            padding-bottom: 35px;
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column;
            padding: 100px 5%;
            .main-content {
                width: 100%;
            }
            .text-side {
                width: 100%;
                margin: 20px 0 0 0;
                padding: 50px;
            }
            @media screen and (max-width: $size-xs-max) {
                .main-content .owl-nav {
                    padding: 0 1%;
                }
                .text-side {
                    padding: 50px 25px;
                    a.btn-link {
                        width: 200px;
                    }
                }
            }
        }
    }
}

#section-text-background {
    .text-side {
        width: 45%;
        background: #F9F7F0;
        border: 3px solid $color-1;
        margin-right: 20px;
        padding: 0 75px 0 5%;
        h4 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-black;
            padding-bottom: 30px; 
        }
    }
    .image-side {
        width: 55%;
        background: url("../images/accueil_section02_img01.jpg") no-repeat;
        background-size: cover;
    }
    @media screen and (max-width: 1650px) {
        .text-side {
            padding: 0 50px 0 5%;
        }
        @media screen and (max-width: $size-md-max) {
            .text-side {
                h4 br {
                    display: none;
                }
            }
            @media screen and (max-width: 1200px) {
                flex-direction: column-reverse;
                padding: 0 5%;
                .image-side {
                    width: 100%;
                }
                .text-side {
                    width: 100%;
                    margin: 20px 0 0 0;
                    padding: 50px;
                }
                @media screen and (max-width: $size-xs-max) {
                    .image-side {
                        background: url("../images/accueil_section02_img01_m.jpg") no-repeat;
                        background-size: cover;
                    }
                    .text-side {
                        padding: 50px 25px;
                    }
                }
            }
        }
    }
}

#section-accreditations {
    margin: 75px 5%;
    padding-bottom: 15px;
    border-bottom: 2px solid $color-1;
    h3 {
        font-weight: bold;
        font-size: $font-size-30;
        color: $color-black;
    }
    @media screen and (max-width: $size-xs-max) {
        flex-direction: column;
        .text-side {
            margin-bottom: 50px;
            h3 {
                font-size: 1.85rem;
            }
        }
    }
}

/*******************************************************************************
* EXPERTISES
*******************************************************************************/

#section-intro {
    background: $color-1;
    padding: 25px 5%;
    position: relative;
    margin-bottom: 150px;
    .title {
        background: $color-1;
        width: fit-content;
        position: absolute;
        top: -50px;
        bottom: -50px;
        padding: 60px 150px 60px 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 80px;
        h2 {
            font-weight: bold;
            font-size: $font-size-40;
            color: #050402;
        }
    }
    @media screen and (max-width: $size-md-max) {
        .title {
            top: -35px;
            bottom: -35px;
            padding: 60px 115px 60px 60px;
        }
        @media screen and (max-width: $size-xs-max) {
            padding: 35px 5%;
            margin-bottom: 100px;
            .title {
                padding: 0 !important;
                position: initial;
            }
        }
    }
}

#section-text-background.expertises {
    .text-side {
        border: none;
        p.color-1 {
            color: $color-1;
        }
    }
    .image-side {
        background: url("../images/expertises_section01_img01.jpg") no-repeat;
        background-size: cover;
    }
    @media screen and (max-width: 1852px) {
        .text-side {
            padding: 0 50px 0 5%;
            h4 br {
                display: none;
            }
        }
        @media screen and (max-width: 1550px) {
            .text-side {
                padding: 50px 50px 50px 5%;
            }
            @media screen and (max-width: $size-md-max) {
                .text-side {
                    
                }
                @media screen and (max-width: 1200px) {
                    flex-direction: column-reverse;
                    padding: 0 5%;
                    .image-side {
                        width: 100%;
                    }
                    .text-side {
                        width: 100%;
                        margin: 20px 0 0 0;
                        padding: 50px;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .image-side {
                            background: url("../images/expertises_section01_img01_m.jpg") no-repeat;
                            background-size: cover;
                        }
                        .text-side {
                            padding: 50px 25px;
                        }
                    }
                }
            }
        }
    }
}

#section-title-image {
    padding: 100px 5% 0 5%;
    h2 {
        font-weight: bold;
        font-size: $font-size-40;
        color: $color-black;
        padding-bottom: 15px;
        border-bottom: 3px solid #868686;
    }
    .section-image {
        padding-top: 100px;
    }
    @media screen and (max-width: $size-sm-max) {
        .section-image {
            padding-top: 75px;
        }
        @media screen and (max-width: $size-xs-max) {
            h2 {
                font-size: 1.55rem;
            }
        }
    }
}

#section-bloc-image {
    padding: 100px 0 0 0;
    .left-side {
        width: 48%;
        background: #F9F7F0;
        margin-right: 15px;
        padding: 75px 50px 75px 5%;
        h4 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-black;
            padding-bottom: 25px;
        }
        .section-list {
            padding: 50px 0 60px 0;
            .left {
                width: 45%;
            }
            .bloc {
                .text {
                    h5 {
                        font-weight: bold;
                        font-size: $font-size-18;
                        color: $color-black;
                    }
                }
            }
        }
        a.btn-link {
            width: 162px;
            &:hover {
                width: 170px;
            }
        }
        &.pic03 {
            width: 52%;
            h4 {
                padding-bottom: 100px;
            }
        }
    }
    .right-side {
        &.pic01 {
            width: 52%;
            background: url(../images/expertises_section03_img01.jpg) no-repeat;
            background-size: cover;
            background-position: center;
            @media screen and (max-width: $size-xs-max) {
                background: url(../images/expertises_section03_img01_m.jpg) no-repeat;
                background-size: cover;
            }
        }
        &.pic03 {
            width: 48%;
            background: url(../images/expertises_section03_img03.jpg) no-repeat;
            background-size: cover;
            background-position: center;
            @media screen and (max-width: $size-xs-max) {
                background: url(../images/expertises_section03_img03_m.jpg) no-repeat;
                background-size: cover;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column-reverse;
        padding: 100px 5% 0 5%;
        .left-side, .left-side.pic03 {
            width: 100%;
            margin-right: 0;
            .section-list {
                flex-direction: column;
                .left {
                    margin-bottom: 1rem;
                    width: 100%;
                }
            }
        }
        .left-side.pic03 {
            h4 {
                padding-bottom: 25px;
            }
        }
        .right-side.pic01, .right-side.pic03 {
            width: 100%;
        }
        @media screen and (max-width: $size-xs-max) {
            .left-side {
                padding: 60px 25px;
            }
        }
    }
}

#section-image-bloc {
    padding: 100px 5% 0 5%;
    .left-side {
        &.pic02 {
            width: 45%;
            background: url(../images/expertises_section03_img02.jpg) no-repeat;
            background-size: cover;
            background-position: center;
            @media screen and (max-width: $size-xs-max) {
                background: url(../images/expertises_section03_img02_m.jpg) no-repeat;
                background-size: cover;
            }
        }
        &.pic04 {
            width: 45%;
            background: url(../images/expertises_section03_img04.jpg) no-repeat;
            background-size: cover;
            background-position: center;
            @media screen and (max-width: $size-xs-max) {
                background: url(../images/expertises_section03_img04_m.jpg) no-repeat;
                background-size: cover;
            }
        }
    }
    .right-side {
        width: 55%;
        background: #BBA1520D;
        margin-left: 15px;
        padding: 75px 50px;
        h4 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #050402;
            padding-bottom: 84px;
        }
        a.btn-link.long {
            width: 228px;
            margin-top: 50px;
            &:hover {
                width: 236px;
            }
        }
        &.pic04 {
            padding: 55px 50px 75px 50px;
            h4 {
                padding-bottom: 25px;
            }
            a.btn-link {
                width: 152px;
                margin-top: 25px;
                &:hover {
                    width: 164px;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column;
        .left-side {
            width: 100% !important;
        }
        .right-side {
            width: 100%;
            margin-left: 0;
            padding: 75px 50px 75px 5%;
            h4 {
                padding-bottom: 25px;
            }
        }
        .right-side.pic04 {
            padding: 75px 50px 75px 5%;
        }
        @media screen and (max-width: $size-xs-max) {
            .right-side {
                padding: 60px 25px !important;
                a.btn-link.long {
                    width: 200px;
                }
            }
        }
    }
}

#section-text {
    margin: 190px 5% 70px 5%;
    background: $color-1;
    padding: 30px;
    .border {
        border: 2px solid #000;
        padding: 73px 94px 73px 32px;
        position: relative;
        h4 {
            font-weight: normal;
            font-size: $font-size-38;
            color: #050402;
            line-height: 1.3;
        }
        .buble01 {
            position: absolute;
            top: -15px;
            left: 23%;
            border: 1px solid #050402;
            padding: 14px;
            border-radius: 40px;
            background: $color-1;
        }
        .buble02 {
            position: absolute;
            bottom: -25px;
            right: 23%;
            border: 1px solid #050402;
            padding: 25px;
            border-radius: 40px;
            background: $color-1;
        }
    }
    @media screen and (max-width: 1650px) {
        .border {
            h4 br {
                display: none;
            }
        }
        @media screen and (max-width: $size-md-max) {
            .border {
                padding: 73px 32px 73px 32px;
                h4 {
                    font-size: 1.7rem;
                }
            }
            @media screen and (max-width: $size-xs-max) {
                margin: 100px 5% 70px 5%;
                padding: 25px;
                .border {
                    padding: 50px 25px;
                    h4 {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#section-intro.realisations {
    .title {
        align-items: initial;
        top: -65px;
        bottom: -65px;
        padding: 60px;
        h2 {
            padding-bottom: 8px;
        }
        p {
            font-size: $font-size-18;
            line-height: 1.3;
        }
    }
    @media screen and (max-width: $size-md-max) {
        .title {
            top: -50px;
            bottom: -50px;
        }
        @media screen and (max-width: $size-xs-max) {
            .title p br {
                display: none;
            }
        }
    }
}

#section-realisations {
    padding: 50px 5% 75px 5%;
    .bloc {
        padding: 0 0 150px 0;
        position: relative;
        .gallery {
            width: 45%;
            background-size: cover !important;
            background-position: center !important;
            margin-right: 15px;
            .img-responsive {
                width: 100%;
            }
        }
        .text-side {
            width: 55%;
            padding: 75px 60px;
            background: #BBA1520D;
            h3 {
                font-weight: bold;
                font-size: $font-size-30;
                color: $color-black;
                text-transform: initial;
                padding-bottom: 43px;
            } 
        }
    }
    @media screen and (max-width: 1200px) {
        .bloc {
            flex-direction: column;
            padding: 0 0 100px 0;
            .gallery {
                width: 80%;
                margin: 0 auto 15px auto;
            }
            .text-side {
                width: 80%;
                margin: auto;
                padding: 65px 50px;
            }
        }
        @media screen and (max-width: $size-sm-max) {
            .bloc {
                .gallery, .text-side {
                    width: 100%;
                }
            }
            @media screen and (max-width: $size-xs-max) {
                .bloc {
                    .text-side {
                        padding: 50px 25px;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* CARRIÈRE
*******************************************************************************/

#section-intro.carriere {
    margin-bottom: 50px;
    @media screen and (max-width: 1715px) {
        margin-bottom: 100px;
        @media screen and (max-width: $size-xs-max){
            margin-bottom: 0;
        }
    }
}


#section-intro-emploi {
    margin: 0 5%;
    justify-content: right;
    border-bottom: 1px solid #2B2B2B;
    padding-bottom: 75px;
    @media screen and (max-width: $size-md-max){
        justify-content: initial;
        @media screen and (max-width: $size-sm-max){
            p br {
                display: none;
            }
            @media screen and (max-width: $size-xs-max){
                padding: 75px 0 50px 0;
            }
        }
    }
}

#section-emploi {
    padding: 35px 5% 50px 5%;
    div.section-wrapper {
        display: inline-grid;
        margin: auto;
        width: 90%;
        max-width: 1600px;;
        justify-items: stretch;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
        @media screen and (max-width: $size-sm-max){
            grid-template-columns: 1fr;
        }
    }
    div.left-side, div.right-side {
        width: 50%;
        @media screen and (max-width: $size-xs-max){
          width: 100%;
        }
    }

    section.emplois-liste {
        display: flex;
        flex-wrap: wrap;
        div.item-poste {
            display: inline-block;
            margin-bottom: 20px;
            background-color: #F2F2F2;
            transition: .5s;
            width: 49%;

            div.title {
                background-color: $color-1;
                padding: 30px 7%;
                h4 {
                    color: #050402;
                    font-size: 20px;
                    font-weight: bold;
                    text-transform: uppercase;
                }
                @media screen and (max-width: 1325px){
                    padding: 30px 5%;
                }
            }
            div.soustitre {
                padding: 30px 7% 0 7%;
                .icon {
                    font-size: 20px;
                    font-weight: bold;
                    text-transform: initial;
                    color: #050402;
                    margin-right: 50px;
                    padding-bottom: 10px;
                    .fa {
                        font-size: 20px;
                        margin-right: 10px;
                        color: #050402;
                    }
                    @media screen and (max-width: 1420px){
                        margin-right: 25px;
                        @media screen and (max-width: 1270px){
                            margin-right: 0;
                            width: 100%;
                            margin-bottom: 15px;
                            @media screen and (max-width: 1110px){
                                margin-right: 25px;
                                width: initial;
                                margin-bottom: 0;
                                @media screen and (max-width: $size-xs-max){
                                    margin-right: 0;
                                    width: 100%;
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: 1325px){
                    padding: 30px 5%;
                }
            }
            div.content-full {
                padding: 0 7% 30px 7%;
                h4, h3, h2 {
                    font-size: 16px;
                    color: #050402;
                    letter-spacing: 1.6px;
                    text-transform: uppercase;
                    padding: 15px 0;
                    font-weight: bold;
                    background: $color-1;
                    width: fit-content;
                    padding: 12px;
                    margin: 30px 0;
                }
                p, li {
                    color: #050402;
                    font-weight: normal;
                    font-size: 16px;
                    text-transform: initial;
                    padding-left: 25px;
                    line-height: 2;
                    @media screen and (max-width: 1420px){
                        padding-left: 0;
                    }
                }
                .btn-wrapper {
                    max-width: 145px;
                    margin-top: 50px;
                    .btn {
                        padding: 20px 5px;
                        background-color: $color-1;
                        border: none;
                        font-weight: bold;
                        font-size: 16px;
                        &:hover {
                            border: none;
                            opacity: 1;
                        }
                    }
                }
                @media screen and (max-width: 1325px){
                    padding: 0 5% 30px 5%;
                }
            }
            &:nth-child(odd) {
                margin-right: 20px;
                @media screen and (max-width: 1110px){
                    margin-right: 0;
                }
            }
            @media screen and (max-width: 1110px){
                width: 100%;
                margin: 0 0 25px 0;
            }
        }
    }
}

section.section-form-emploi {
    padding: 60px 10% 25px 10%;
    margin-bottom: 25px;
    background-color: #F9F7F0;
    h3 {
        font-weight: bold;
        color: $color-black;
        font-size: $font-size-30;
        padding-bottom: 45px;
        text-transform: initial;
        @media screen and (max-width: 360px) {
            font-size: 1.4rem;
        }
    }
    .form-group {
        margin-bottom: -10px;
    }
    input {
        height: 95px;
        border-radius: 3px;
        border: 1px solid #868686;
    }
    textarea#message {
        border-radius: 3px;
        border: 1px solid #868686;
    }
    input::placeholder {
        font-weight: normal !important;
        font-size: 16px !important;
        color: #868686 !important;
        text-transform: initial;
    }
    textarea::placeholder {
        font-weight: normal !important;
        color: #868686 !important;
        padding: 35px 5px;
        font-size: 16px !important;
        text-transform: initial;
    }

    form label.filedoc {
        background: $color-1;
        width: 200px;
        color: #050402;
        font-weight: bold;
        font-size: $font-size-20;
        transition: 0.3s;
        &:hover {
            width: 210px;
            transition: 0.3s;
            opacity: 1;
        }
    }
    .btn {
        border-radius: 0;
        border: none;
        background: $color-1;
        font-weight: bold;
        font-size: 16px;
        padding: 20px;
        &:hover {
            border: none;
            opacity: 0.7;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        .pl0 , .pr0, {
            padding: 0!important;
        }
        form label.filedoc {
            margin-right: 0;
        }
        form .buttons {
            justify-content: center;
            margin: 0 0 50px 0;
        }
        .btn-submit {
            margin-top: 15px;
            .btn.submit-emploi {
                width: 200px;
            }
        }
    }
    @media screen and (max-width: 1325px){
        padding: 60px 8% 25px 8%;
        @media screen and (max-width: $size-sm-max){
            padding: 60px 5% 25px 5%;
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-intro.coordonnees {
    margin-bottom: 30px;
    @media screen and (max-width: $size-xs-max) {
        margin-bottom: 0;
    }
}

#section-coordonnees {
    background: #BBA1520D;
    padding: 85px 5% 100px 5%;
    border-bottom: 3px solid $color-1;
    .left-side {
        width: 23%;
        margin-right: 50px;
        h2 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #171717;
        }
    }
    .right-side {
        width: 77%;
        .top {
            margin-bottom: 63px;
            .bloc {
                h4 {
                    font-weight: bold;
                    font-size: $font-size-22;
                    color: $color-black;
                    padding-bottom: 20px;
                }
                h5, a {
                    font-weight: normal;
                    font-size: $font-size-20;
                    color: #A6ADB4;
                }
            }
        }
        .bottom {
            h3 {
                font-weight: bold;
                font-size: $font-size-28;
                color: $color-black;
                text-transform: initial;
                padding-bottom: 35px;
            }
            h5, a {
                font-weight: bold;
                font-size: $font-size-20;
                color: #A6ADB4;
            }
            .icon {
                width: 10%;
            }
        } 
        .bloc {
            width: 32%;
        }
    }
    @media screen and (max-width: 1645px) {
        .right-side {
            .bloc {
                width: 35%;
            }
        }
        @media screen and (max-width: 1550px) {
            .right-side {
                .bottom {
                    h5, a {
                        font-size: 0.9rem;
                    }
                }
            }
            @media screen and (max-width: 1400px) {
                flex-direction: column;
                .left-side {
                    width: 100%;
                    margin: 0 0 50px 0;
                }
                .right-side {
                    width: 100%;
                }
                @media screen and (max-width: $size-sm-max) {
                    .right-side {
                        .bloc {
                            width: 100%;
                            &.middle {
                                padding: 35px 0;
                            }
                        }
                        .bottom {
                            flex-direction: column;
                            h3 {
                                padding-bottom: 20px;
                            }
                        }
                    }
                    @media screen and (max-width: $size-xs-max) {
                        padding: 75px 5%;
                        .right-side {
                            .top {
                                flex-direction: column;
                                margin-bottom: 50px;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-form-heure {
    padding: 55px 5% 125px 5%;
    .section-form {
        width: 65%;
        margin-right: 100px;
        padding: 50px 120px;
        box-shadow: 0px 0px 6px #00000017;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #171717;
            padding-bottom: 40px;
            text-transform: initial;
        }
    }
    .section-heures {
        width: 35%;
        margin-top: 50px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #171717;
            text-transform: initial;
            padding-bottom: 50px;
        }
        .jours-heures {
            padding-bottom: 50px;
            h4, h5 {
                font-weight: bold;
                font-size: $font-size-20;
                color: $color-black;
                line-height: 2;
            }
            h5 {
                font-weight: normal;
                color: #A6ADB4;
                text-align: right;
            }
            .jour {
                width: 40%;
            }
        }
        h4.color-1 {
            font-weight: 900;
            font-size: $font-size-20;
            color: $color-1;
        }
    }
    @media screen and (max-width: 1600px) {
        .section-form {
            padding: 50px 75px;
        }
        @media screen and (max-width: 1265px) {
            flex-direction: column-reverse;
            .section-heures {
                width: 50%;
                margin: 0 0 50px 0;
            }
            .section-form {
                width: 100%;
                margin-right: 0;
            }
            @media screen and (max-width: $size-sm-max) {
                .section-heures {
                    width: 75%;
                }
                .section-form {
                    padding: 50px;
                }
                @media screen and (max-width: $size-xs-max) {
                    .section-heures {
                        width: 100%;
                    }
                    .section-form {
                        padding: 50px 30px;
                        .col-sm-6.pl0, .col-xs-12.col-sm-6.pr0 {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}

#section-map {
    margin-bottom: 35px;
    @media screen and (max-width: $size-xs-max) {
        div#map-canvas {
            height: 500px !important;
        }
    }
}

#content p a {
    color: $color-1;
}


/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
    

}

@media screen and (max-width: $size-sm-max) {
   

}

@media screen and (max-width: $size-xs-max) {
    

}
