footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        padding: 0 5%;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .justify-between;
        @extend .text-color-grey;
        @extend .font-footer;
        
        .left-side {
            .logo {
                margin-bottom: 70px;
            }
            .copyright {
                font-family: $font-family-2;
                font-size: $font-size-18;
                color: #F9F7F0;
                margin-bottom: 10px;
            }
        }
        
        .center-side {
            width: 50%;
            margin: 0 50px;
            .bloc {
                h3 {
                    font-weight: bold;
                    font-size: $font-size-30;
                    color: #F9F7F0;
                    text-transform: initial;
                    padding-bottom: 20px;
                }
                h4, a {
                    font-weight: normal;
                    font-size: $font-size-20;
                    color: $color-1;
                }
                a {
                    text-decoration: underline !important;
                    transition: 0.3s;
                    &:hover {
                        text-decoration: none !important;
                        transition: 0.3s;
                    }
                }
            }
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column;
            align-items: initial;
            .left-side .logo {
                margin-bottom: 35px;
            }
            .center-side {
                width: 60%;
                margin: 50px 0;
            }
            @media screen and (max-width: $size-sm-max) {
                .center-side {
                    width: 85%;
                }
                @media screen and (max-width: $size-xs-max) {
                    .center-side {
                        width: 100%;
                        flex-direction: column;
                        .bloc {
                            h3 {
                                padding-bottom: 15px;
                            }
                            &.middle {
                                margin: 25px 0;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        min-height: 550px;
        @media screen and (max-width: $size-xs-max) {
            min-height: 650px;
        }
    }
}
